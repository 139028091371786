import React, { useEffect, useState } from "react"
import {
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { toast } from "react-toastify"
import { generateClientSecret } from "../../api/payment"
import Input from "../input"
import VisaImage from "../../images/visa.svg"
import MastercardImage from "../../images/master-card.svg"
import BillInfo from "./info"
import "./styles.css"
import Spinner from "../../images/spinner.svg"
import { useTranslation } from "../../utils"

const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: "18px",
      color: "#5E6977",
      letterSpacing: "0.025em",
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#EE3F3F",
    },
  },
}

const CardForm = ({ userData, onSuccess, onError, onCancel }) => {
  const { t } = useTranslation()
  const stripe = useStripe()
  const elements = useElements()
  const [cardName, setCardName] = useState("")
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (userData) {
      setCardName((userData.name + " " + userData.lastname).toUpperCase())
    }
  }, [userData])

  const handleSubmit = async event => {
    event.preventDefault()

    if (!stripe || !elements) {
      return
    }

    setLoading(true)

    const response = await generateClientSecret(
      userData.name,
      userData.lastname,
      userData.email,
      userData.site
    )
    const result = await stripe.confirmCardPayment(
      response.data.guest.billing.payments.generateForWordpress.clientSecret,
      {
        payment_method: {
          card: elements.getElement(CardNumberElement),
          billing_details: {
            name: cardName,
            email: userData.email,
          },
        },
      }
    )
    setLoading(false)
    if (result.error) {
      toast(result.error.message)
      onError()
    } else {
      if (result.paymentIntent.status === "succeeded") {
        onSuccess()
        toast(t("Payment was successful"))
      }
    }
  }

  return (
    <form className="bill-user-form" onSubmit={handleSubmit}>
      {loading && (
        <div className="wt-loader-wrapper">
          <img src={Spinner} alt="spinner" />
        </div>
      )}
      <div className="wt-flex-1">
        <div className="bill-user-form__form">
          <h3 className="bill-user-form__title">{t("Account Information")}</h3>
          <div className="wt-margin-bottom-20">
            <label htmlFor="cardNumber" className="wt-input__label">
              {t("Credit Card Number")}
            </label>
            <CardNumberElement id="cardNumber" options={ELEMENT_OPTIONS} />
          </div>

          <div className="wt-flex wt-margin-bottom-20">
            <div className="wt-flex-1">
              <label htmlFor="expiry" className="wt-input__label">
                {t("Expiry Date")}
              </label>
              <CardExpiryElement id="expiry" options={ELEMENT_OPTIONS} />
            </div>
            <div className="wt-flex-1 wt-margin-left-25">
              <label htmlFor="cvc" className="wt-input__label">
                {t("CVV Code")}
              </label>
              <CardCvcElement id="cvc" options={ELEMENT_OPTIONS} />
            </div>
          </div>

          <div className="wt-margin-bottom-20">
            <Input
              value={cardName}
              onChange={e => setCardName(e.target.value.toUpperCase())}
              label={t("Name on Card")}
            />
          </div>

          <div className="wt-flex">
            <div className="bill-user-form__title">{t("Payment method")}</div>

            <img
              src={VisaImage}
              className="wt-margin-left-auto wt-margin-right-10"
              alt="visa"
              width={44}
            />
            <img src={MastercardImage} alt="mastercard" width={44} />
          </div>
        </div>
      </div>
      <div className="bill-info">
        <BillInfo />

        <div className="bill-info__submit">
          <span className="wt-text wt-text--red" onClick={onCancel}>
            {t("Cancel")}
          </span>

          <button
            className="wt-button wt-button--primary"
            type="submit"
            disabled={!stripe}
          >
            {t("Place order")}
          </button>
        </div>
      </div>
    </form>
  )
}

export default CardForm
