import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useAnalytics } from "../../hooks/useTracking"
import { openHubspotChat, useTranslation } from "../../utils"
import "./styles.css"

const WpSolveSection = () => {
  const { t } = useTranslation()
  const analytics = useAnalytics()

  return (
    <section className="wp-solve-section">
      <div className="container wp-solve-section__content">
        <div className="wt-flex-1 wt-flex-column">
          <h1 className="wp-solve-section__title">
            {t("We will solve your problem with WordPress Security")}
          </h1>
          <span className="wp-solve-section__sub-title">
            {t("Methods of WordPress Security Site Audit")}
          </span>
          <div className="wp-solve-section__text">
            <p>
              •{" "}
              {t(
                "Penetration testing report for management, containing an overall assessment of the level of web application security."
              )}
            </p>
            <p>
              •{" "}
              {t(
                "Recommendations to address previously identified security vulnerabilities"
              )}
            </p>
            <p>
              •{" "}
              {t(
                "Description of the pentest process. Reporting about all identified vulnerabilities, and results of vulnerabilities exploitation."
              )}
            </p>
            <p>• {t("Description of the identified security risks")}</p>
          </div>
        </div>
        <div className="wt-flex-1 wt-flex-column wt-flex-middle wt-flex-center">
          <div className="wp-solve-section__trigger">
            {t("Want to become our reseller?")}
          </div>
          <button
            className="wt-button"
            onClick={() => {
              analytics.contactUs()
              openHubspotChat()
            }}
          >
            {t("Contact us")}
          </button>
        </div>
      </div>
    </section>
  )
}

export default WpSolveSection
