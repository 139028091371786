import React from "react"
import Input from "../input"
import "./styles.css"
import VisaImage from "../../images/visa.svg"
import MastercardImage from "../../images/master-card.svg"
import { useForm } from "react-hook-form"
import BillInfo from "./info"
import classNames from "classnames"
import {
  emailPattern,
  namePattern,
  urlPattern,
  useTranslation,
} from "../../utils"

const UserForm = ({ onSubmit, onCancel }) => {
  const { t } = useTranslation()
  const { register, handleSubmit, errors, reset } = useForm()
  return (
    <form className="bill-user-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="wt-flex-1">
        <div className="bill-user-form__form">
          <h3 className="bill-user-form__title">{t("Account Information")}</h3>
          <div className="bill-user-form__fullname">
            <Input
              label={t("Name")}
              className={classNames("wt-flex-1", {
                "wt-input_red-border": errors.name,
              })}
              placeholder={t("Enter your name")}
              name="name"
              ref={register({ required: true, pattern: namePattern })}
            />
            <Input
              label={t("Last name")}
              name="lastname"
              className={classNames("wt-flex-1 wt-margin-left-25", {
                "wt-input_red-border": errors.lastname,
              })}
              placeholder={t("Enter last name")}
              ref={register({ required: true, pattern: namePattern })}
            />
          </div>
          <Input
            label={t("E-mail")}
            name="email"
            className={classNames("wt-margin-bottom-25", {
              "wt-input_red-border": errors.email,
            })}
            placeholder={t("Enter your email")}
            ref={register({ required: true, pattern: emailPattern })}
          />
          <Input
            label={t("Web-site")}
            name="site"
            className={classNames("wt-margin-bottom-25", {
              "wt-input_red-border": errors.site,
            })}
            placeholder={t("Enter your web-site")}
            ref={register({ required: true, pattern: urlPattern })}
          />
          <div className="wt-flex">
            <img
              src={VisaImage}
              className="wt-margin-right-10"
              alt="visa"
              width={44}
            />
            <img src={MastercardImage} alt="mastercard" width={44} />
          </div>
        </div>
      </div>
      <div className="bill-info">
        <BillInfo />

        <div className="bill-info__submit">
          <span
            onClick={() => {
              onCancel()
              reset()
            }}
            className="wt-text wt-text--red"
          >
            {t("Cancel")}
          </span>

          <button className="wt-button wt-button--primary" type="submit">
            {t("Next")}
          </button>
        </div>
      </div>
    </form>
  )
}

export default UserForm
