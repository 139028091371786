import React from "react"
import LightBubbleImage from "../../images/light_bubble2.png"
import DarkBubbleImage from "../../images/dark_bubble2.png"
import ScanIcon from "../../images/scan.svg"
import WifiIcon from "../../images/wifi.svg"
import PixelsIcon from "../../images/pixels.svg"
import CodeIcon from "../../images/code.svg"
import Code2Icon from "../../images/code2.svg"
import SafeChatIcon from "../../images/safe-chat.svg"
import "./styles.css"
import { useTranslation } from "../../utils"

const WpAuditFeatures = () => {
  const { t } = useTranslation()
  return (
    <section className="audit-features-section">
      <img
        className="audit-features-section__decor1 display-xs-none display-sm-none"
        src={LightBubbleImage}
        alt="Decor"
      />
      <img
        className="audit-features-section__decor2 display-xs-none display-sm-none"
        src={DarkBubbleImage}
        alt="Decor"
      />

      <div className="container">
        <div className="audit-features-section__title">{t("Our features")}</div>
        <div className="row">
          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={ScanIcon} alt="Finger scan" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "Fingerprinting. We scan your webserver and detect running software and network services."
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={WifiIcon} alt="Wifi" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "Identification of vulnerabilities of network services and applications."
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={PixelsIcon} alt="Pixels" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "Gathering of preliminary information about customer's web application. At this point we use the same sources of information that are available to Internet attackers."
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={CodeIcon} alt="Code" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "The exploitation of vulnerabilities. Methods and tools are selected individually for each type of vulnerability. Public and own pentest tools are used."
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={Code2Icon} alt="Code" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "Analysis of web application of the customer. By means of automated tools and manual methods we try to reveal different types of vulnerabilities: SQL Injection, Cross-Site Scripting, Content spoofing, OS Commanding, vulnerabilities"
                )}
              </div>
            </div>
          </div>

          <div className="col-4 col-md-6 col-sm-12">
            <div className="audit-features-section__block">
              <div className="audit-features-section__icon">
                <img src={SafeChatIcon} alt="Safe chat" />
              </div>
              <div className="audit-features-section__text">
                {t(
                  "Attempt to get unauthorized access to confidential information or a customer`s limited access information. Access rights to the various information resources of the company are investigated with privileges obtained at different stages of testing process."
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpAuditFeatures
