import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import "./styles.css"
import { useTranslation } from "../../utils"

const WpAuditSection = () => {
  const { t } = useTranslation()
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "audit_macbook.png" }) {
        childImageSharp {
          fluid(maxWidth: 1100, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <section className="audit-section">
      <div className="container">
        <div className="audit-section__content">
          <div className="audit-section__title">
            {t("Complete WordPress Security Audit for $250")}
          </div>
          <div className="audit-section__hint">
            {t("Guaranteed free support for 30 days!")}
          </div>
          <div className="audit-section__text">
            {t(
              "Our experts will conduct a full security audit of your WordPress site based on best practices and prepare a comprehensive report for you. Moreover, we will install for free our protection system for WordPress sites so that you forget about hackers and can focus on your business."
            )}
          </div>
          <div style={{ width: "100%" }}>
            <Img fluid={data.placeholderImage.childImageSharp.fluid} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpAuditSection
