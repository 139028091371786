import React from "react"
import Bill from "../../components/bill"
import "./styles.css"
import { useTranslation } from "../../utils"

const WpBill = () => {
  const { t } = useTranslation()
  return (
    <section className="wp-bill">
      <div className="container">
        <div className="wp-bill__content">
          <h2 className="wp-bill__title">{t("Secure checkout")}</h2>
          <p>
            {t(
              "We guarantee support within 30 days from the service only if the owner of the WordPress site complied with our recommendations after the service. Working hours are from Monday to Friday. Work takes 2-3 days. To clean the site, you must provide access to the administrative area of your site, FTP access and access to the control panel."
            )}
          </p>
          <p>
            {t(
              "We also need SSH access, if you have one. After payment, our managers will contact you by mail to obtain access to your site and additional information. Only authorized security analysts have access to this information."
            )}
          </p>

          <div className="wp-bill__form">
            <Bill />
          </div>
        </div>
      </div>
    </section>
  )
}

export default WpBill
