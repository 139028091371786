import React from "react"
import { useTranslation } from "../../utils"

const Info = () => {
  const { t } = useTranslation()
  return (
    <div className="wt-flex-column wt-flex-1">
      <h3 className="bill-info__title">{t("Your bill")}</h3>
      <div className="wt-flex wt-flex-between wt-flex-1 wt-margin-bottom-25">
        <span className="bill-info__reason">
          {t("Wordpress Security Audit")}
        </span>
        <span className="bill-info__cost">250$ / {t("site")}</span>
      </div>
      <div className="wt-flex wt-flex-between wt-flex-middle wt-margin-bottom-25">
        <span className="wt-text wt-text--size-100 wt-text--light-gray">
          {t("Sales Tax (TBD)")}
        </span>
        <span className="wt-text wt-text--size-100">
          30 {t("day Guarantee")}
        </span>
      </div>
      <div className="wt-flex wt-flex-between wt-flex-middle">
        <span className="wt-text wt-text--blue wt-text--upper wt-text--bold">
          {t("Order total")}
        </span>
        <span className="wt-text wt-text--blue wt-text--upper">$250</span>
      </div>
    </div>
  )
}

export default Info
