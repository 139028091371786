import axios from "../axios"
import { toast } from "react-toastify"
import { getQueryParams } from "../utils"

export const generateClientSecret = async (name, lastname, email, site) => {
  try {
    const query = `
      mutation ($input: paymentForWordpressInput!) {
        guest {
          billing {
            payments {
              generateForWordpress(input: $input) {
                clientSecret
              }
            }
          }
        }
      }`

    const ref = getQueryParams(window.location.search)["ref"]

    const queryData = {
      query,
      variables: {
        input: {
          name,
          lastname,
          email,
          site,
          affilate: ref || "",
        },
      },
    }
    const response = await axios.post("/graphql", queryData)
    if (response.status === 200 && !response.data.errors) {
      return response.data
    } else {
      response.data.errors.map(item => {
        let msg = item.message
        if (item.message === "USER_ALREADY_REGISTERED") {
          msg = "User already registered"
        }
        toast(msg)
        return false
      })
    }
  } catch (e) {}
}
