import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DarkBubbleImage from "../../images/dark_bubble.png"
import LightBubbleImage from "../../images/light_bubble.png"
import Header from "../../sections/header"
import "./styles.css"
import WpAuditSection from "../../sections/wp-audit"
import WpAuditFeatures from "../../sections/wp-audit-features"
import WpSolveSection from "../../sections/wp-solve"
import Footer from "../../sections/footer"
import WpBill from "../../sections/wp-bill"

const WpAuditPage = () => {
  return (
    <Layout>
      <SEO title="WordPress Security Audit" />
      <Header />

      <div className="audit-page">
        <img className="audit-page__decor1" src={DarkBubbleImage} alt="Decor" />
        <img
          className="audit-page__decor2"
          src={LightBubbleImage}
          alt="Decor"
        />
      </div>

      <WpAuditSection />
      <WpAuditFeatures />
      <WpSolveSection />
      <WpBill />
      <Footer />
    </Layout>
  )
}

export default WpAuditPage
