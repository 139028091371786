import React, { useState } from "react"
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import "./styles.css"
import UserForm from "./user-form"
import CardForm from "./card-form"
import SuccessIcon from "../../images/success.svg"
import FailIcon from "../../images/fail.svg"
import { useTranslation } from "../../utils"
import { CONFIG } from "../../config"
import { useAnalytics } from "../../hooks/useTracking"

const stripePromise = loadStripe(CONFIG?.STRIPE_TOKEN)

const StepsEnum = Object.freeze({
  UserData: 1,
  CreditCard: 2,
  Success: 3,
  Error: 4,
})

const Bill = () => {
  const { t, locale } = useTranslation()
  const [step, setStep] = useState(StepsEnum.UserData)
  const [userData, setUserData] = useState(null)
  const analytics = useAnalytics()

  const onSubmitStep1 = async data => {
    analytics.goNext(
      data.name.trim(),
      data.lastname.trim(),
      data.email.trim(),
      data.site.trim()
    )
    setUserData({
      name: data.name.trim(),
      lastname: data.lastname.trim(),
      email: data.email.trim(),
      site: data.site.trim(),
    })
    setStep(StepsEnum.CreditCard)
  }
  const onCancel = () => {
    setUserData(null)
    setStep(StepsEnum.UserData)
  }

  const Steps = () => {
    switch (step) {
      case StepsEnum.UserData:
        return <UserForm onCancel={onCancel} onSubmit={onSubmitStep1} />
      case StepsEnum.CreditCard:
        return (
          <CardForm
            userData={userData}
            onCancel={onCancel}
            onError={() => setStep(StepsEnum.Error)}
            onSuccess={() => setStep(StepsEnum.Success)}
          />
        )
      case StepsEnum.Success:
        return (
          <div className="wt-flex-column wt-flex-center wt-flex-middle wt-width-1-1">
            <img src={SuccessIcon} alt="Sucess" />
            <h2>{t("Your payment is success")}</h2>
            <span className="wt-margin-bottom-25">
              {t("We sent the payment results to your mail")}
            </span>
            <button
              onClick={() => {
                setUserData(null)
                setStep(StepsEnum.UserData)
              }}
              className="wt-button wt-button--primary"
            >
              {t("Go sign up")}
            </button>
          </div>
        )
      case StepsEnum.Error:
        return (
          <div className="wt-flex-column wt-flex-center wt-flex-middle wt-width-1-1">
            <img src={FailIcon} alt="Sucess" />
            <h2>{t("Your payment was declined")}</h2>
            <span className="wt-margin-bottom-25">
              {t("Try again or contact your bank advisor")}
            </span>
            <button
              onClick={() => {
                setStep(StepsEnum.UserData)
              }}
              className="wt-button wt-button--primary"
            >
              {t("Try again")}
            </button>
          </div>
        )
      default:
        return null
    }
  }

  return (
    <Elements stripe={stripePromise} options={{ locale }}>
      <div className="bill">{Steps()}</div>
    </Elements>
  )
}

export default Bill
